<template>
  <div>
    <PageTopSection title="Prohibited Items List" />
    <section class="blog-details">
        <div class="container">
          <div class="row">
            <div class="col-lg">
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:center;line-height:normal;background:white;"><strong><span style="font-size:24px;color:black;">Afridext Prohibited Items List</span></strong></p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Afridext Integrated Services will not accept the under-listed items for transportation or shipping:</span></p>
                <ul type="disc" style="margin-bottom:0cm;">
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Bees</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Ammunition</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Switchblades &amp; flick knives</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Arms and ammunition</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Fireworks</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Bearer Bonds</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Products &nbsp; &nbsp; &nbsp; &nbsp;or articles from plants or animals under the protection of Convention on International Trade in Endangered Species (CITIES)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Live Fish</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Hazardous waste or substances</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Gambling devices</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Cash (in any denomination), currency, negotiable instruments</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Live Households Pets</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Cash on Delivery COD shipment</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Money (Paper/ coins)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Live Monkeys</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Human remains</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Pornography and its items</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Lottery tickets &amp; materials</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Negotiable instrument such as bonds, cash letters, stocks, similar negotiable instruments that are equivalent to cash</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Shipments prohibited or restricted by any statute, regulation, or law</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Perfumes (there are exceptions to these; please contact Afridext support for more information)</span></li>
                </ul>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><strong><span style="font-size:19px;color:black;">Psychoactive/Psychotropic Substances</span></strong></p>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Afridext Integrated Services prohibits the under-listed psychoactive/psychotropic substances for transportation or shipping:</span></p>
                <ul type="disc" style="margin-bottom:0cm;">
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Benzodiazepines</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Alcohol</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Morphine, Heroin, Oxycodone (Percocet, Oxycontin), Hydrocodone (Vicodin)&nbsp;</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Diazepam (Valium)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Flunitrazepam (Rohypnol)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Alprazolam (Xanax)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Lorazepam (Ativan)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Methamphetamine (Meth)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Clonazepam (Klonopin)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">GHB</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Cocaine</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Nicotine</span></li>
                </ul>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><strong><span style="font-size:19px;color:black;">Afridext List of Prohibited Items for Export by the Nigeria<br>&nbsp;Customs Service (NCS)</span></strong></p>
                <ul type="disc" style="margin-bottom:0cm;">
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Firearms</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Scrap metals</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Maize</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Artifacts &amp; Antiquities</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Crayfish flavor/chicken flavor/ meat flavor Maggi cubes</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Dry fish or dry meat or crayfish (TheeSome Exceptions apply, contact support for assistance)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Certain fruits and vegetables (there are exceptions to these; please contact Afridext support for more information)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Timber (sawn or rough)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Animal products, Animal by-products, and some animals (examples are original coral beads, elephant tusks)</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Rubber lumps and rubber latex in unprocessed form</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Endangered species of wildlife animals and their products</span></li>
                    <li style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><span style="font-size:16px;color:black;">Raw hides &amp; skin (these include wet blue as well as all unfinished leather)</span></li>
                </ul>
                <p style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;font-size:11.0pt;font-family:&quot;Calibri&quot;,sans-serif;text-align:justify;line-height:normal;background:white;"><strong><span style="font-size:19px;color:black;">N.B.:</span></strong><span style="font-size:19px;color:black;">&nbsp;There may be unique law and regulations in countries such as South Africa, Nigeria, France, Germany, Portugal, Italy, India, and Cyprus.</span><span style="font-size:19px;color:black;">&nbsp;</span><span style="font-size:19px;color:black;">Please, contact Afridext support for more information or inquiries about your shipment.</span></p>
            </div>
            <!-- /.col-lg -->
          </div>
        </div>
     </section>
  </div>
</template>

<script>

  import PageTopSection from '/src/components/sections/PageTopSection.vue'
  export default {
    name: 'ProhibitedList',
    metaInfo: function() {
        return {
            title: "Prohibited Items List | Respectmart",
        }
    },
    data() {
        return {
        };
    },

    components: {
        PageTopSection
    },

  }
</script>
